
.footer {

  /*** Footer ***/

  footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

    footer ul {
      display: flex;
      justify-content: space-around;
      font-size: 2rem;
      min-height: 3rem; /* required for safari mobile landscape */
    }

    footer li {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    footer ul a:hover {
      font-size: 2.2rem;
    }

    .footnote {
      font-size: 0.5em;
      padding-bottom: 2rem;
    }

}
    
@media only screen and (max-width : 600px) {

  .footer {

    /***** Footer *****/

    footer.mobile {
      display: flex !important;
    }

    .footnote {
      font-size: 0.667em;
    }

  }

}

