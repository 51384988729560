.contact {

/*** Main Section ***/

main {
  max-width: 850px;
  padding: 0 1rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  min-height: 100vh;
}

.header {
  text-align: center;
  padding: 0 0 2rem;
}

h1 {
  padding: 2rem;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  max-width: 600px;
  width: 100%;
}

label {
  display: inline-block;
  font-family: var(--secondary-font);
  transform: var(--rotate);
  margin: 0 0 0.5rem;
}

.field {
  margin: 0 0 2rem;
}

input,
textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: 0.875em;
  font-family: var(--font-primary);
  color: var(--type-body-color);
  border: 1px solid var(--light-grey);
}

textarea {
  resize: vertical;
  min-height: 10rem;
  max-height: 20rem;
}

input::placeholder,
textarea::placeholder {
  color: var(--light-grey);
  opacity: 1;
}

.invalid {
  position: absolute;
  font-size: 0.75rem;
  color: red;
}

.success {
  color: green;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

#submit-msg {
  margin: 0 0 2rem;
}

.btn {
  text-align: center;
  margin: 2rem 0 0;
  min-height: 3rem;
}

  #submit-msg {
    position: initial;
  }

  button {
    display: none;
    color: var(--light-color);
    padding: 0.5rem 0.75rem;
    font-size: 1.2rem;
    background-color: var(--primary-color);
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease;
  }

    button:hover {
      transform: var(--rotate);
      padding: 0.65rem 0.9rem;
      font-size: 1.3rem;
    }

    button:active {
      transform: scale(0.9);
      transition: 0.1s ease;
    }

}

@media only screen and (max-width : 600px) {

  .contact {

    /***** Main Section *****/

    main {
      min-height: auto;
    }

    form {
      max-width: 365px;
    }

    button {
      transform: var(--rotate);
      min-height: initial;
    }

  }

}


