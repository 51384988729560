/* Warning CSS not scoped here */
/*.project-list {*/ 

.projects {
    width: 100%;
    box-sizing: border-box;
    padding: 5rem;
  }



@media only screen and (max-width : 1024px) {
  
  .projects {
      padding: 1rem;
  }

}


@media only screen and (max-width : 600px) {

  /***** Main Section *****/

  .projects {
      padding: 0.5rem;
  }
  
}

