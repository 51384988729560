.about {
    
  /*** Main Section ***/

  main {
    max-width: 850px;
  }

  section {
    display: flex;
    padding: 2rem;
  }

  .title {
    text-align: center;
    padding: 0 0 1rem;
  }

  .content > .wrapper > div {
    padding: 0 0 1rem;
  }

  .content > .wrapper > div:last-child {
    padding: 0;
  }

  .bio p {
    padding: 0 0 0.75rem;
  }

    .bio p:last-child {
      padding: 0;
    }

}


@media only screen and (max-width : 900px) {
  
  .about {

    /***** Main Section *****/

    section {
      flex-flow: column wrap;
      padding: 1rem 2rem;
    }

    .blurb {
      padding: 2rem 1rem;
      text-align: center;
    }

    .content {
      padding: 1rem;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }

  }

}


