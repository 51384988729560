.project {

  /*** Main Section ***/

  .gallery {
    padding: 0 1rem;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

    .gallery li {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;
      box-sizing: border-box;
      max-width: 650px;
      flex-flow: column wrap; /* fix for safari stretching images */
    }

    .gallery img {
      max-width: 100%;
      width: 1000px; // fix to display svg images, should be at least main column size
    }

  #description .wrapper {
    // height is necessary to center the container with justify-content/align-items, but has no effect due to top/bottom: 0 set for overflow
    // height: 100vh; 
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  #description .container {
    /*width: inherit;*/
    position: fixed;
    // padding: 0 2rem; // padding top should be 0 when centering with flex justify-content/align-items
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    
    // overflow fix fix for lengthy content
    overflow-y: auto;
    padding: 5rem 2rem 0;
    top: 0; /* necessary for overflow to work in position fixed */
    bottom: 0; /* necessary for overflow to work in position fixed */
  }

  #description .container > * { /* all the immediate children */
    max-width: 400px;
    width: 100%;
  }

    .title {
      text-align: center;
      padding: 0 0 1rem;
    }

      h1 {
        padding: 0 0 0.5rem;
      }

    /*.description {
      font-size: 0.875em;
      white-space: pre-wrap;
    }*/

    .description p {
      padding: 0 0 0.75rem;
    }

    .description p:last-child {
      padding: 0;
    }

    .details .head h3,
    .details .info p {
      height: 2rem;
    }

    .details .head {
      text-align: center;
    }

    .details .head h3 {
      display: block;
      transform: var(--rotate);
    }

    .details .info {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }

    .details .info a:hover {
      transform: none;
      font-size: inherit;
    }

  @media only screen and (max-width : 1024px) {

    /***** Main Section *****/

    #description.offset-nav {
      margin-top: 0;
    }

    .title {
      padding: 1rem 0;
    }

    #description .wrapper {
      height: auto;
    }

    #description .container {
      position: initial;
      padding: 0 2rem; // removes extra padding from overflow fix
      overflow-y: unset; // prevents overflow issue in mobile
    }

  }

  @media only screen and (max-width : 600px) {

    .title {
      padding: 2rem 0 1.5rem;
    }

    .description {
      padding: 1.5rem 0 0;
    }

  }

}