/*Global CSS */

:root {
    --primary-color: #1e78c1;
    --light-color: #fff;
    --light-grey: #c4c4c4;
    --type-body-color: #707070;
    --primary-font: 'Montserrat', sans-serif;
    --secondary-font: 'Lora', serif;
    --rotate: rotate(-7deg);
    --navbar-width: 270px; /* desktop left fixed navbar */
    --navbar-height: 4rem; /* mobile top fixed navbar: nav + separator (nav = wordmark height + padding) */
  }
  
  html, body, div, h1, h2, h3, h4, a, ul, p {
      margin: 0;
      padding: 0;
      border: 0;
      text-decoration: none;
      list-style: none;
      font-size: 100%;
      line-height: 1.4rem;
      vertical-align: baseline;
      scroll-behavior: smooth;
  }
  
  html, body {
    min-height: 100%;
    position: relative;
    color: var(--type-body-color);
  }
  
  body, h1, h2 {
    font-family: var(--primary-font);
  }
  
  h3, h4, h5, a, ul {
    display: inline-block;
    font-family: var(--secondary-font);
  }
  
  h1 { font-size: 1.2em }
  h2 { font-size: 1.1em }
  /*h3 { font-size: 0.9em }*/
  h4, h5, p { font-size: 0.875em }
  
  a {
    color: var(--type-body-color);
    transition: all 0.25s ease;
  }
  
    a:hover,
    a.active {
      transform: var(--rotate);
      color: var(--primary-color);
      font-size: 1.2rem;
    }
  
    a.active {
      font-weight: bold;
    }
  
  .active {
    transform: var(--rotate);
  }
  
  strong {
    font-weight: bold;
  }
  
  .mobile {
    display: none !important;
  }
  
  /* Disables right click on images */
  /* img {
    pointer-events: none;
  } */

  /* 
    CSS for zooming in on image with label and checkbox input 
    Note: not the same as the modal solution in image-zoom.js
  */
    .check-zoom img {
      transition: transform 0.25s ease;
      cursor: zoom-in;
    }

    .check-zoom input[type=checkbox]:checked~img {
      transform: scale(1.5);
      cursor: zoom-out;
      z-index: 1;
      position: relative;
    }
  
  .font-primary { font-family: var(--primary-font); }
  .font-secondary { font-family: var(--secondary-font); }
  
  /*** Grid ***/
  .sm-12, .sm-8, .sm-6, .sm-4,
  .md-12, .md-8, .md-6, .md-4,
  .lg-12, .md-8, .lg-6, .lg-4,
  .xlg-7, .xlg-5 {
    float: left;
    box-sizing: border-box;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  
  .sm-12 { width: 100%; }
  .sm-8 { width: 66.6666666666%; }
  .sm-6 { width: 50%; }
  .sm-4 { width: 33.3333333333%; }
  
  @media only screen and (min-width : 600px) { 
    .md-12 { width: 100%; }
    .md-8 { width: 66.6666666666%; }
    .md-6 { width: 50%; }
    .md-4 { width: 33.3333333333%; }
  }
  
  @media only screen and (min-width : 900px) { 
    .lg-12 { width: 100%; }
    .lg-8 { width: 66.6666666666%; }
    .lg-6 { width: 50%; }
    .lg-4 { width: 33.3333333333%; }
      
    .offset-lg-4-left { margin-left: 33.3333333333%; }
  }
  
  @media only screen and (min-width : 1024px) { 
    .xlg-7 { width: 58.3333333333%; }
    .xlg-5 { width: 41.6666666666%; }
  }
  
  .offset-nav {
    margin-left: var(--navbar-width);
  }
          
  .separator-container {
    padding: 2rem 0;
    width: 80%;
    align-self: center;
    }
    
    .separator {
        border-top: 2px dotted var(--light-grey);
        display: block;
    }
  
  /*** Main Section ***/
  
  main {
    min-width: 330px;
    max-width: 1650px;
    box-sizing: border-box;
  }
  
  @media only screen and (max-width : 600px) {
  
    html {
      font-size: 0.875em;
    }
  
    .desktop {
      display: none !important;
    }
  
    .mobile {
      display: block !important;
    }
  
    .offset-nav {
      margin-left: 0;
      margin-top: var(--navbar-height);
    }

    /***** Main Section *****/
    main {
      min-width: auto;
    }
  
  }