.thumbnail {

  .thumb {
      position: relative;
      height: 0; /* In order to preserve aspect ratio 1:1 of the box*/
      padding-bottom: 50%; /* Height of the box is given with padding, % relates to the Grid width */
    } 

    .thumb img {
      width: 100%;
      transition: all 0.25s ease;
    }
  
    .title {
      position: absolute;
      color: #fff;
      text-align: center;
    }
  
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 2rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
    }
  
    a > .title {
      display: block;
      opacity: 0;
      z-index: 2;
    }
  
    a:hover > .title {
      opacity: 1;
    }
  
    a:hover > img {
      filter: blur(2.5px); /* grayscale(100%) sepia(100%) hue-rotate(180deg) */
      width: 80%;
    }
  
    a:after {
      content: "";
      background-color: var(--primary-color);
      opacity: 0;
      position: absolute;
      z-index: 1;
      width:100%;
      height: 100%;
      filter: blur(5px);
      box-sizing: border-box;
    }
  
    a:hover:after {
      opacity: 0.65;
      width: calc(80% - 2rem);
      height: calc(80% - 2rem);
    }


}

@media only screen and (min-width : 900px) { .thumbnail .thumb { padding-bottom: 33.3333333333%; } }  /* % relates to the width, media query relates to the reponsive Grid */

@media only screen and (max-width : 1024px) {

  .thumbnail {
    
    a {
      padding: 1rem;
    }
    
    a:hover:after {
      width: calc(80% - 1rem);
      height: calc(80% - 1rem);
    }

  }
  
}

@media only screen and (max-width : 600px) {

  .thumbnail {

    .thumb {
      height: inherit;
      padding: 0;
    }
    
    a {
      position: initial;
      padding: 0.5rem;
      flex-flow: column wrap;
    }
    
    a:hover {
      transform: none;
      font-size: 1rem;
    }
    
    a:hover > img {
      width: 100%;
    }
    
    a:hover:after {
      top: 0;
      width: 100%;
      height: 83%;
    }
    
    
    .title {
      color: var(--type-body-color);
      position: initial;
      opacity: 1 !important;
      padding: 0.25rem 0 0;
    }
  
      .title h2 {
        font-size: 0.9em;
      }
    

  }
}

