.navbar {

    #top {
        position: absolute;
        top: 0;
    }


    /*** Menu ***/

    nav {
        width: var(--navbar-width);
        height: 100vh; 
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        }
        
        nav .icon-ad,
        nav .wordmark-adgd {
            width: 150px;
        }
        
        nav ul {
            flex: 2 1 auto;
            text-align: center;
        }
        
        nav li {
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

}

@media only screen and (max-width : 600px) {

    .navbar {
        
        #top {
            top: calc(-1 * var(--navbar-height));
        }
    
        nav {
            width: 100vw;
            height: auto;
            flex-flow: row wrap;
            justify-content: center;
            background-color: var(--light-color);
            z-index: 9;
            /*box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.1);*/
        }
        
            nav ul {
            display: flex;
            justify-content: space-evenly;
            }
        
            nav li {
            width: 2.5rem;
            }
        
            .wordmark-ad {
            height: 2.25rem;
            padding: 0.875rem 0 0.875rem 1rem;
            }
        
            nav .separator-container {
            padding: 0;
            order: 1;
            }  

    }
        
}
    
